import {createBrowserRouter, matchPath, RouteObject,} from 'react-router-dom'
import {Layout} from '../../../components/layouts/Layout'
import {HomePage} from '../../../pages/HomePage'
import {SearchPage} from '../../../pages/SearchPage'
import {PriceListPage} from '../../../pages/PriceListPage'
import {NewsListPage, NewsPage} from '../../../pages/NewsPages'
import {FAQListPage, FAQPage} from '../../../pages/FAQPages'
import {CatalogPage} from '../../../pages/CatalogPage'
import {CatalogProductPage} from '../../../pages/CatalogProductPage'
import {UserProfilePage} from '../../../pages/UserProfilePage'
import {UserBalancePage} from '../../../pages/UserBalancePage'
import {UserOrdersPage} from '../../../pages/UserOrdersPage'
import {UserSuppliesPage} from '../../../pages/UserSuppliesPage'
import {UserShipmentsPage} from '../../../pages/UserShipmentsPage'
import {UserRefusalsPage} from '../../../pages/UserRefusalsPage'
import {UserMatchingPage} from '../../../pages/UserMatchingPage'
import {UserWaitingListPage} from '../../../pages/UserWaitingListPage'
import {UserBufferPage} from '../../../pages/UserBufferPage'
import {CatalogComparePage} from '../../../pages/CatalogComparePage'
import {DocumentPage} from '../../../pages/DocumentPage'
import {NotFoundPage} from '../../../pages/NotFoundPage'
import {ManagerSettingsPage} from '../../../pages/ManagerSettingsPage'
import {AuthorizationPage} from '../../../pages/AuthorizationPage'
import {RegistrationPage} from '../../../pages/RegistrationPage'
import {ProtectedRoute} from '../../../components/ProtectedRoute'
import {PriceListGeneratePage} from '../../../pages/PriceListGeneratePage'
import {AuctionListPage, AuctionPage} from '../../../pages/AuctionPage'
import {MarkdownPage} from '../../../pages/MarkdownPage'
import {MarkdownProductPage} from '../../../pages/MarkdownProductPage'

export type AppRoute =
    | '/user/buffer'
    | '/user/waiting-list'

export const routes = [
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <HomePage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/sign-in',
                element: <AuthorizationPage />,
            },
            {
                path: '/sign-up',
                element: <RegistrationPage />,
            },
            {
                path: '/search',
                element: (
                    <ProtectedRoute>
                        <SearchPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/price-list',
                element: (
                    <ProtectedRoute>
                        <PriceListPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/price-list/generate',
                element: (
                    <PriceListGeneratePage />
                ),
            },
            {
                path: '/news',
                element: (
                    <ProtectedRoute>
                        <NewsListPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/news/:id',
                element: (
                    <ProtectedRoute>
                        <NewsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/faq',
                element: (
                    <ProtectedRoute>
                        <FAQListPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/faq/:id',
                element: (
                    <ProtectedRoute>
                        <FAQPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/catalog/:categoryId',
                element: (
                    <ProtectedRoute>
                        <CatalogPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/product/:itemId',
                element: (
                    <ProtectedRoute>
                        <CatalogProductPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/compare/:items',
                element: (
                    <ProtectedRoute>
                        <CatalogComparePage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/profile',
                element: (
                    <ProtectedRoute>
                        <UserProfilePage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/balance',
                element: (
                    <ProtectedRoute>
                        <UserBalancePage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/orders',
                element: (
                    <ProtectedRoute>
                        <UserOrdersPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/orders/:orderId',
                element: (
                    <ProtectedRoute>
                        <UserOrdersPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/supplies',
                element: (
                    <ProtectedRoute>
                        <UserSuppliesPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/shipment/:id',
                element: (
                    <ProtectedRoute>
                        <UserShipmentsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/refusals',
                element: (
                    <ProtectedRoute>
                        <UserRefusalsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/matching',
                element: (
                    <ProtectedRoute>
                        <UserMatchingPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/waiting-list',
                element: (
                    <ProtectedRoute>
                        <UserWaitingListPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/user/buffer',
                element: (
                    <ProtectedRoute>
                        <UserBufferPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/manager/users-settings',
                element: (
                    <ProtectedRoute>
                        <ManagerSettingsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/page/:name',
                element: (
                    <ProtectedRoute>
                        <DocumentPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '*',
                element: <NotFoundPage />,
            },
            {
                path: '/auctions',
                element: (
                    <ProtectedRoute>
                        <AuctionListPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/auctions/:id',
                element: (
                    <ProtectedRoute>
                        <AuctionPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/markdown',
                element: (
                    <ProtectedRoute>
                        <MarkdownPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/markdown/:itemId',
                element: (
                    <ProtectedRoute>
                        <MarkdownProductPage />
                    </ProtectedRoute>
                ),
            },
        ],
    },
] as RouteObject[]

export function routeGet (route : AppRoute, base : string, path : string) : boolean
{
    const match = matchPath(base, path);

    return match?.pattern?.path === route;
}

export const routerConfig = createBrowserRouter(routes)
