import {Logo} from "../Icons";
import {Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../shared/hooks/hooks";

const TDXLogo = () => {
    const {settings} = useAppSelector(state => state.General.common)
    const {isCustomPrice} = useAppSelector(state => state.Profile.profile)

    return (
        <Link to="/" className={`logo ${settings?.ui_settings?.logo_hide?.value ? 'hide': ''}`}>
            {settings?.ui_settings ? (
                <>
                    {(isCustomPrice || settings?.ui_settings?.region?.value === 'ru') && (settings?.ui_settings?.logo_rf?.value as string)?.length>0 ? (
                        <img src={settings.ui_settings.logo_rf.value as string} alt={'logo'} />
                    ) : (
                        <Logo />
                    )}
                </>
            ): (
                <Skeleton variant="rectangular" width={100} height={50} />
            )}
        </Link>
    )
}

export default TDXLogo
