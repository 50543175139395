import {get, getFileAsBlob, post} from './api'

export interface MoveToNewOrderParameters {
    products: {
        line: number
        quantity: number
    }[]
    order?: number
}

const OrderService = {
    getOrder: (id: number) => get(`/order/${id}`),
    moveToNewOrder: (id : number, body: any) => post(`/order/${id}/product/move`, body),
    mergeOrders: (body: any) => post(`/order/merge`, body),
    reserveOrder: (id: number) => post(`/order/${id}/reserve`),
    reserveOrderCheck: (id: number) => post(`/order/${id}/reserve-check`),
    unReserveOrder: (id: number) => post(`/order/${id}/dereserve`),
    unDeliveryOrder: (id: number) => post(`/order/${id}/undelivery`),
    deleteProducts: (id: number, products: number[]) =>
        post(`/order/${id}/products/remove`, {products}),
    getOrderStockDates: (id: number) : Promise<number[]> =>
        get(`/order/${id}/delivery/dates`).then(response => {
            const res = response.dates;
            if (res?.length > 0)
            {
                return res as number[];
            }
            else
            {
                return []
            }
        }),
    moveToStock: (id: number, date: number) => post(`order/${id}/warehouse`, {date}),
    moveToShipment: (id: number, params: any) => post(`/order/${id}/delivery`, params),
    confirmProduct: (orderId: number, productId: number, confirm: boolean) =>
        post(`/order/${orderId}/product/${productId}/confirm`, {confirm}),
    updateProduct: (orderId: number, productId: number, quantity: number) =>
        post(`/order/${orderId}/product/${productId}/update`, {quantity}),
    deleteProduct: (orderId: number, productId: number) =>
        get(`/order/${orderId}/product/${productId}/remove`),
    updateComment: (id: number, comment: string) =>
        post(`/order/${id}/update/comment`, {comment}),
    updateOrderQuantity: (id: number, quantities: {id: number, quantity: number}[]) =>
        post(`/order/${id}/products/update`, quantities),
    getInvoice: (id: number, params: any) => getFileAsBlob(`/order/${id}/invoice`, params),
    updateDeliveryDataOrder: (body: any) => post(`/order/delivery-data`, body),
    getRoutes: (id: number) => get(`/order/${id}/routes`),
    getDeliveryRoutes: (id: number, params: any) => get(`/order/${id}/delivery-routes`, {params}),
    getDeliveryTime: (id: number, params: any) => get(`/order/${id}/delivery-time`, {params}),
    getDeliveryTypes: () => get(`/order/delivery-types`),
    getCertificate: (orderId: number, params: any) =>
        getFileAsBlob(`/order/${orderId}/certificate`, params),
    generateCertificate: (orderId: number, params: any) =>
        post(`/order/${orderId}/certificate`, params, {validateStatus: (status: number) => status === 200 || status === 404}),
    getBarcode: (orderId: number) =>
        getFileAsBlob(`/order/${orderId}/barcodes`),
    getBSO: (orderId: number) =>
        getFileAsBlob(`/order/${orderId}/bso`),
}

export const OrderRequestsEnum = {
    getRoutes: (id: number) => `/order/${id}/routes`,
    getDeliveryRoutes: (id: number) => `/order/${id}/delivery-routes`,
    getDeliveryTime: (id: number) => `/order/${id}/delivery-time`,
    getDeliveryTypes: () => `/order/delivery-types`,
}

export default OrderService
