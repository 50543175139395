import {createAsyncThunk} from '@reduxjs/toolkit'
import {
    CartService,
    OrderRearrangeSerializer,
} from '../../../api/cart.service'
import {SET_PROFILE_ORDERS} from '../../actions/ProfileActions'
import {enqueueSnackbar} from 'notistack'
import {Link} from 'react-router-dom'
import {
    CartAddResponseSerializer,
    OrderCreateResponseSerializer,
    AddToCartArgs,
    AddToCartBody,
    RemoveFromCartArgs,
    Cart,
} from './types'
import {getNoun} from '../../../utils/getNoun'
import {splitProductId} from '../../../utils/prepareProduct'

export const getCart = createAsyncThunk<Cart>('cart/getCart', async (_, thunkAPI) => {
    try {
        return await CartService.getCart()
    } catch (e) {
        console.error(e)
        return thunkAPI.rejectWithValue('cart/getCart error, see logs')
    }
})

export const addToCart = createAsyncThunk<
    Cart | CartAddResponseSerializer,
    AddToCartArgs
>(
    'cart/addToCart',
    async ({body, bySKU = false, detailed = false, region = 'by'}, thunkAPI) => {
        try {
            const cart_detailed : CartAddResponseSerializer = (await CartService.addToCart(
                //{'body':body, 'bySKU':bySKU},
                body,
                {raw: true}
            )) as CartAddResponseSerializer;
            const cart = cart_detailed.data;

            if (!bySKU) {
                if (cart_detailed.meta.ignored_products.length === 0)
                {
                    enqueueSnackbar(
                        <>
                            Товар успешно добавлен в&nbsp;
                            <Link style={{color: 'inherit'}} to="/user/buffer">
                                {region === 'ru' ? 'корзину': 'буфер'}
                            </Link>
                        </>,
                        {variant: 'success'}
                    )
                }
                else
                {
                    enqueueSnackbar(
                        <>
                            Ошибка добавления.
                        </>,
                        {variant: 'error'}
                    )
                }
            } else {
                const idArr = body.map(({id}) => id)
                /*
                const addedProductsCount = cart.products.filter(({ext_sku}: any) =>
                    skuArr.includes(ext_sku)
                ).length
                */

                /*
                let total_products = {}
                cart.products.map(item => {
                    if(total_products[item.ext_sku]){
                        total_products[item.ext_sku] += item.max_quantity
                    } else {
                        total_products[item.ext_sku] = item.max_quantity
                    }
                })
                */

                if (
                    cart_detailed.meta.ignored_products.length > 0 ||
                    cart_detailed.meta.partial_products.length > 0
                )
                {
                    let message = '';
                    message +=
                        cart_detailed.meta.partial_products.map(
                            (o) => o.details + '<br>'
                        )
                        .join('')
                    message +=
                        cart_detailed.meta.ignored_products.map((o) => o.details + '<br>')
                        .join('')

                    enqueueSnackbar(
                        <div dangerouslySetInnerHTML={{ __html: message.trimEnd() }}/>,
                        {
                            variant: 'warning',
                            autoHideDuration: 40000,
                        },
                    )
                }
                else
                {
                    const addedProductsCount = (
                        idArr.length - cart_detailed.meta.ignored_products.length
                    );
                    const firstText = getNoun(
                        addedProductsCount,
                        'товар', 'товара', 'товаров'
                    )
                    const secondText = getNoun(
                        addedProductsCount,
                        'добавлен', 'добавлены', 'добавлены'
                    )

                    enqueueSnackbar(
                        `${addedProductsCount} из ${idArr.length} ` +
                        `${firstText} ${secondText} в ${region === 'ru' ? 'корзину': 'буфер'}`,
                        {variant: 'success'}
                    )
                }
            }

            if (detailed)
            {
                return cart_detailed;
            }
            else
            {
                return cart
            }
        } catch (e) {
            console.error(e)
            return thunkAPI.rejectWithValue('cart/addToCart error, see logs')
        }
    }
)

export const removeFromCart = createAsyncThunk<Cart, RemoveFromCartArgs>(
    'cart/removeFromCart',
    async ({ids, region = 'by'}, thunkAPI) => {
        try {
            const cart = await CartService.removeFromCart({ product: ids.map(id => splitProductId(id)) })

            enqueueSnackbar(`Товары успешно убраны из ${region === 'ru' ? 'корзины': 'буфера'}`, {variant: 'success'})

            return cart
        } catch (e) {
            console.error(e)
            return thunkAPI.rejectWithValue('cart/removeFromCart error, see logs')
        }
    }
)

export const updateQuantityInCart = createAsyncThunk<Cart, {id: string; quantity: number}>(
    'cart/updateQuantityInCart',
    async ({id, quantity}, thunkAPI) => {
        try {
            return await CartService.updateCartById({product: splitProductId(id), quantity})
        } catch (e) {
            console.error(e)
            return thunkAPI.rejectWithValue('cart/updateQuantityInCart error, see logs')
        }
    }
)

export const createOrder = createAsyncThunk<Cart, {
    comment: string;
    products: string[]
}>(
    'cart/createOrder',
    async ({comment, products}, thunkAPI) => {
        try {
            const products2 = products.map(item => splitProductId(item))

            const create_detailed = await CartService.createOrder(
                {comment, products : products2}, true
            );

            if (create_detailed?.meta?.status === 'Fail')
            {
                if (
                    create_detailed.meta.messages !== undefined &&
                    create_detailed.meta.messages !== null &&
                    create_detailed.meta.messages?.length > 0
                ){
                    create_detailed.meta.messages.forEach((item: any) => {
                        if (item['status'] == 'Fail')
                        {
                            enqueueSnackbar(
                                <> <pre> {item['details']} </pre> </>,
                                {
                                    variant: 'error',
                                    autoHideDuration: 40000,
                                }
                            )
                        }
                        else
                        {
                            enqueueSnackbar(
                                <> <pre> {item['details']} </pre> </>,
                                {
                                    variant: 'success',
                                    autoHideDuration: 40000,
                                }
                            )
                        }
                    });
                } else if (create_detailed?.meta?.details?.length > 0) {
                    enqueueSnackbar(
                        <> <pre> {create_detailed.meta.details} </pre> </>,
                        {
                            variant: 'error',
                            autoHideDuration: 40000,
                        }
                    )
                }
                /*
                let message = '';

                message += create_detailed.meta.details;

                if (
                    create_detailed.meta.supplier_1c_errors !== undefined &&
                    create_detailed.meta.supplier_1c_errors !== null &&
                    create_detailed.meta.supplier_1c_errors?.length > 0
                )
                {
                    message += '\n' + create_detailed.meta.supplier_1c_errors.join('\n')
                }

                enqueueSnackbar(
                    <> <pre> {message} </pre> </>,
                    {
                        //preventDuplicate: message === 'Недопустимый токен.',
                        variant: 'error',
                        autoHideDuration: 40000,
                    }
                )
                */
            }
            else
            {
                const create_detailed2 = create_detailed as OrderCreateResponseSerializer;
                const orders = create_detailed2.data.orders;

                thunkAPI.dispatch({
                    type: SET_PROFILE_ORDERS,
                    data: {orders},
                })

                enqueueSnackbar(
                    <div className="createOrderMsg">
                        <Link style={{color: 'inherit'}} to="/user/orders">
                            {
                              (create_detailed2.data.orders_created.length === 1
                              ? 'Заказ'
                              : 'Заказы') + ' '
                            }
                            {
                              create_detailed2.data.orders_created.map(
                                (o) => '#' + o.ext_id
                              ).join(', ')
                            }
                        </Link>
                        <span>
                          <br/>успешно {
                            create_detailed2.data.orders_created.length === 1
                            ? 'создан'
                            : 'созданы'
                          }
                        </span>
                    </div>,
                    {variant: 'success'}
                )
            }

            return await CartService.getCart()
        } catch (e) {
            console.error(e)
            return thunkAPI.rejectWithValue('cart/createOrder error, see logs')
        }
    }
)

export const rearrangeOrder = createAsyncThunk<void, {
    source_order_id: number;
    target_order_id: number;
    products: string[]
}>(
    'cart/rearrangeOrder',
    async ({source_order_id, target_order_id, products}, thunkAPI) => {
        try {
            const body : OrderRearrangeSerializer = {
                target_order_id,
                source_products: products.map(item => splitProductId(item)),
            }

            if (source_order_id !== null)
            {
                body.source_order_id = source_order_id
            }

            const update_products = await CartService.rearrangeOrder(body)

            enqueueSnackbar(
                <>
                    Товары перенесены успешно
                </>,
                {variant: 'success'}
            )

            return update_products;
        } catch (e) {
            console.error(e)
            return thunkAPI.rejectWithValue('cart/rearrangeOrder error, see logs')
        }
    }
)

export const getStockQuantity = createAsyncThunk('cart/getStockQuantity', async (_, thunkAPI) => {
    try {
        return await CartService.getStockQuantity()
    } catch (e) {
        console.error(e)
        return thunkAPI.rejectWithValue('cart/getStockQuantity error, see logs')
    }
})
