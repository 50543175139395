import {ErrorBoundary} from 'react-error-boundary'
import {Outlet, useLocation} from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ErrorFallback from '../ErrorFallback'
import {Suspense, useEffect} from 'react'
import {TDXCircularProgress} from '../UI'
import {ScrollToTop} from '../ScrollToTop'
import {SnackBarProvider} from '../../app/providers/SnackbarProvider'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import {Maintenance} from '../Maintenance'
import utils_settings from "../../utils/settings";
import Popups from "../UI/Popups/Popups";
import {getPolls} from "../../store/actions/GeneralActions";
import {namedRequestsInProgress} from "../../store/RequestSelectors";
import {GeneralRequestsEnum} from "../../api/general.service";
import * as Sentry from "@sentry/react";


export const Layout = () => {
    const dispatch = useAppDispatch()
    const {isLoggedIn, user} = useAppSelector(state => state.Auth)
    const {settings} = useAppSelector(state => state.General.common)
    const {pathname} = useLocation()

    const {isLoading} = useAppSelector(state => ({
        isLoading: namedRequestsInProgress(state, GeneralRequestsEnum.getPolls),
    }))

    useEffect(() => utils_settings.settings_init(settings, dispatch), [])

    useEffect(() => {
        if (isLoggedIn) {
            Sentry.setUser({ email: user?.username, partner_id: user?.partner?.id });
            Sentry.setTag("partner_id", user?.partner?.id);
        }

        if (isLoggedIn && !isLoading) {
            dispatch<any>(getPolls())
        }
    }, [isLoggedIn])

    useEffect(() => {
        Sentry.getCurrentScope().setTransactionName(pathname);
        Sentry.captureMessage(pathname);
    }, [pathname])

    useEffect(() => {
        if(settings?.main?.metrics) {
            const script = document.createElement('script');
            script.innerHTML = settings.main.metrics;
            document.head.appendChild(script);
        }
    }, [settings]);

    return settings?.maintenance?.enabled?.value ? (
        <Maintenance />
    ): (
        <SnackBarProvider>
            {isLoggedIn && <Header />}
            <main>
                <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
                {/*<ErrorBoundary FallbackComponent={ErrorFallback}>*/}
                    <Suspense fallback={<TDXCircularProgress />}>
                        <Outlet />
                    </Suspense>
                {/*</ErrorBoundary>*/}
                </Sentry.ErrorBoundary>
            </main>
            <Footer />
            <Popups />
            <ScrollToTop />
        </SnackBarProvider>
    )
}
