import {createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {CatalogSchema} from './types'
import {getCategory} from './services/getCategory/getCategory'
import {SortBy, OfferStatus} from '../../../api/settings.service'
import {getCategoryProducts} from './services/getCategoryProducts/getCategoryProducts'
import {getMarkdownProducts} from './services/getMarkdownProducts/getMarkdownProducts'
import {prepareProductId} from '../../../utils/prepareProduct'
import {Category} from "../catalogCategoriesSlice/types";

const initialState: CatalogSchema = {
    category: null,
    products: [],
    loadingCategory: false,
    loadingProducts: false,
    stats: {},
    status: [
      'transit',
      'stock',
      'reserve'
    ],
    sortBy: 'default',
}

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        updateSortBy: (state, action: PayloadAction<SortBy>) => {
            state.sortBy = action.payload;
        },
        updateStatus: (state, action: PayloadAction<OfferStatus[]>) => {
          if (action.payload.length === 0)
          {
            throw Error('not_implemented');
          }

          state.status = action.payload;
        },
        reset: (state) => {
            state.category = initialState.category
        },
        resetProducts: (state) => {
            state.products = initialState.products
        },
    },
    extraReducers: builder => {
        builder.addCase(getCategory.pending, (state: CatalogSchema) => {
            state.loadingCategory = true
        })
        builder.addCase(getCategory.rejected, (state: CatalogSchema) => {
            state.loadingCategory = false
        })
        builder.addCase(getCategory.fulfilled, (state: CatalogSchema, action) => {
            state.category = action.payload as Category
            state.loadingCategory = false
        })
        // -----
        builder.addCase(getCategoryProducts.pending, (state: CatalogSchema) => {
            state.loadingProducts = true
        })
        builder.addCase(getCategoryProducts.rejected, (state: CatalogSchema) => {
            state.loadingProducts = false
        })
        builder.addCase(getCategoryProducts.fulfilled, (state: CatalogSchema, action) => {
            if (action?.payload?.products) {
                state.products = action.payload.products
                state.stats = action.payload.stats
                state.loadingProducts = false
            }
        })
        // -----
        builder.addCase(getMarkdownProducts.pending, (state: CatalogSchema) => {
            state.loadingProducts = true
        })
        builder.addCase(getMarkdownProducts.rejected, (state: CatalogSchema) => {
            state.loadingProducts = false
        })
        builder.addCase(getMarkdownProducts.fulfilled, (state: CatalogSchema, action) => {
            if (action?.payload?.products) {
                state.products = action.payload.products
                state.stats = action.payload.stats
                state.loadingProducts = false
            }
        })
    },
})

export const {actions: catalogActions} = catalogSlice
export const {reducer: catalogReducer} = catalogSlice
