import {OfferStatus} from "../api/settings.service";

export function prepareProductId (
    product : {
        contract_id?: number,
        catalogapp_id?: number
        contract?: number,
        ext_id?: string | number,
        ext_subid?: string | number,
        product_code?: string | number,
        sku?: string,
        supplier_id: number | null,
        status?: OfferStatus
    }
) : string {
    if (product?.contract_id !== undefined && product?.catalogapp_id !== undefined) {
        return [
            product?.supplier_id ?? '',
            product.contract_id,
            product.catalogapp_id,
            product?.product_code ?? product?.ext_subid ?? product?.ext_id ?? ''
        ].join(':')
    } else if (
        product?.contract !== undefined &&
        product?.contract !== null &&
        product?.ext_id !== undefined &&
        product?.ext_id !== null
    ) {
        let offer_id : string = null;

        if (product?.status === 'transit')
        {
            offer_id = product.sku
        }
        else
        {
            offer_id = `${product.ext_id}`
        }
        return [product.contract, offer_id].join(':')
    }
    return null
}

export function splitProductId (productId : string) : {
    contract_id: number | null,
    catalogapp_id: number | null,
    supplier_id: number | null,
    ext_subid: string | null
} {
    let contract_id : number | null;
    let catalogapp_id : number | null;
    let supplier_id : number | null;
    let ext_subid : string | null;

    const a = productId.split(':');

    if (a[0] !== '')
    {
        supplier_id = parseInt(a[0])
    }
    else
    {
        supplier_id = null;
    }

    if (a[1] !== '')
    {
        contract_id = parseInt(a[1])
    }
    else
    {
        contract_id = null;
    }

    if (a[2] !== '')
    {
        catalogapp_id = parseInt(a[2])
    }
    else
    {
        catalogapp_id = null;
    }

    if (a[3] !== '')
    {
        ext_subid = a[3]
    }
    else
    {
        ext_subid = '';
    }

    return {
        contract_id, catalogapp_id, supplier_id, ext_subid
    };
}
