//import {store} from '../store/store'
//import AuthService from './auth.service'
//
class Settings<T, T2 = Record<string, T>> {
    private _key : string;
    constructor ({key} : {key : string}) {
        this._key = key;
    }

    all () : T2 {
        return JSON.parse(localStorage.getItem(this._key)) as T2;
    }

    get (id : string, value?: T) : (T | null) {
        try
        {
            const storage = JSON.parse(
                window.localStorage.getItem(this._key)
            ) as T2;

            const storage2 = storage as any

            return storage2?.[id] ?? value
        }
        catch (e)
        {
            console.error(e);
            return value || null;
        }
    }

    set (id : string, config : T) {
        const settings : Record<string, T> =
            JSON.parse(localStorage.getItem(this._key)) ?? {}

        settings[id] = {
            ...settings[id],
            ...config,
        }

        localStorage.setItem(this._key, JSON.stringify(settings))
    }

    remove (id : string) {
        const settings = JSON.parse(localStorage.getItem(this._key)) ?? {}
        delete settings[id]
        localStorage.setItem(this._key, JSON.stringify(settings))
    }
}

class SessionSettings<T, T2 = Record<string, T>> {
    private _key : string;
    constructor ({key} : {key : string}) {
        this._key = key;
    }

    all () : T2 {
        return JSON.parse(sessionStorage.getItem(this._key)) as T2;
    }

    get (id : string, value?: T) : (T | null) {
        try
        {
            const storage = JSON.parse(
                window.sessionStorage.getItem(this._key)
            ) as T2;

            const storage2 = storage as any

            return storage2?.[id] ?? value
        }
        catch (e)
        {
            console.error(e);
            return value || null;
        }
    }

    set (id : string, config : T) {
        const settings : Record<string, T> =
            JSON.parse(sessionStorage.getItem(this._key)) ?? {}

        settings[id] = {
            ...settings[id],
            ...config,
        }

        sessionStorage.setItem(this._key, JSON.stringify(settings))
    }

    remove (id : string) {
        const settings = JSON.parse(sessionStorage.getItem(this._key)) ?? {}
        delete settings[id]
        sessionStorage.setItem(this._key, JSON.stringify(settings))
    }
}

const tableSettings = new Settings<any>({key: 'table-settings'});
const timerSettings = new Settings<
    any,
    {
        balance?: Record<number, number>,
    }
>({key: 'timer-settings'});
const popupSettings = new Settings<any>({key: 'popup-settings'});
const sessionPopupSettings = new SessionSettings<any>({key: 'popup-settings'});

export type OfferStatus = 'stock' | 'transit' | 'unavailable' | 'reserve' | 'markdown'

export type SortBy = 'cheap' | 'expensive' | 'default' | 'condition';

class CategorySettings extends Settings<
  any,
  {
    sort_by: SortBy,
    vendor: string[],
    price: {
      from?: number,
      to?: number
    },
    status: OfferStatus[],
  }
> {
}

const SettingsService = {
    tableSettings,
    Settings,
    timerSettings,
    popupSettings,
    sessionPopupSettings,
}

export default SettingsService
