import {get, /*post*/} from './api'

const CatalogService = {
    getCategories: () => get('/categories'),
    getCategory: (id: string, signal: any) => get(`/category/${id}`, {signal}),
    getCategoryProducts: (id: string, signal: any, params: any) => get(`/category/${id}/products`, {signal, params}),
    getProduct: (id: string, signal: any) => get(`/product/${id}`, {signal}),
    getMarkdownProducts: (signal: any, params: any) => get(`/markdown/products`, {signal, params}),
    getMarkdownProduct: (id: string, signal: any) => get(`/markdown/${id}`, {signal}),
    getMarkdownFilter: (signal: any) => get(`/markdown/filter`, {signal}),
    compareProducts: (ids: number[]) => get(`/compare/${ids.join('+')}`),
    resetProductsCache: (params: any) => get(
        '/products/status',
        {
            //need_reset: JSON.stringify(false),
            params: {
                ...(params !== undefined ? params : {}),
            }
        }
    )
}

export const CatalogRequestsEnum = {
    getCategories: '/categories',
    getCategory: (id: number) => `/category/${id}`,
    getCategoryProducts: (id: number) => `/category/${id}/products`,
    getProduct: (id: string) => `/product/${id}`,
    getMarkdownProducts: () => `/markdown/products`,
    getMarkdownProduct: (id: string) => `/markdown/${id}`,
    getMarkdownFilter: () => `/markdown/filter`,
    compareProducts: '/compare',
}

export default CatalogService
