import AuctionService, {AuctionRequestsEnum} from '../../api/auction.service'
import {requestHelper} from '../../helpers/requestHelper'

export const SET_AUCTION = 'SET_AUCTION'
export const SET_AUCTIONS = 'SET_AUCTIONS'
export const SET_BID_AUCTION = 'SET_BID_AUCTION'

export interface AuctionState {
    auctions: AuctionListSerializer[] | undefined[],
    auction: AuctionSerializer | null,
}

export interface AuctionListSerializer {
    id: number,
    active: boolean,
    name: string,
    description: string,
    date_start: number,
    date_finish: number,
    price: number,
    final_price: number,
    bids?: {
        user_id: number,
        price: number,
        partner_name?: string,
        created?: number,
    }[],
    products: AuctionProductSerializer[],
    currency?: string,
    need_stat?: boolean,
    auction_stat?: {
        users_count: number,
        users_count_online: number,
    }
}

export interface AuctionSerializer {
    id: number,
    name: string,
    active: boolean,
    description: string,
    date_start: number,
    date_finish: number,
    price: number,
    final_price: number,
    order_id: number,
    order_ext_id: string,
    bids?: {
        user_id: number,
        price: number,
        partner_name?: string,
        created?: number,
    }[],
    currency?: string,
    need_stat?: boolean,
    products: AuctionProductSerializer[],
    steps?: number[],
    prev?: string,
    next?: string,
}

export interface AuctionProductSerializer {
    id: number,
    product_id: number,
    category?: {
        id: number,
        name: string,
    },
    photos?: string[],
    name?: string,
    image?: string,
    description?: string,
    quantity?: number,
}

export const getAuctionList = () => (dispatch : any) => {
    requestHelper(dispatch, AuctionRequestsEnum.getAuctionList, async () => {
        const auctions = await AuctionService.getAuctionList()
        dispatch({
            type: SET_AUCTIONS,
            data: {auctions},
        })
    })
}

export const getAuction = (id: string, params?: Record<string, any>) => (dispatch: any) => {
    requestHelper(dispatch, AuctionRequestsEnum.getAuction(id), async () => {
        const auction = await AuctionService.getAuction(id, params)
        dispatch({
            type: SET_AUCTION,
            data: {auction},
        })
    })
}