export interface RangeValue {
    from: number | string | null,
    to: number | string | null,
    text?: string,
    name?: string,
}
export type DictValue = Record<string | number,boolean>;
export type TextValue = Record<string,string>;

export type SmartFilter = Record<string, {
    is_active: boolean,
    total: number,
    available: number,
}>;

export type SmartFilters = Record<string, Record<string, number>>;

export type SmartFiltersData = {
    smartFilters: SmartFilters,
    disableEmptyValues: boolean,
}

export type FilterType =
    | 'dictionary'
    | 'slider_range'
    | 'dictionary_range'
    | 'number_range'
    | 'date_range'
    | 'text'
    | 'text_number'
    ;

export interface FilterDataData {
    placeholder?: RangeValue | DictValue,
    option?: {
        from: number | string | null
        to: number | string | null
    }
    options?: {
        id: string | number
        name: string
        //is_active?: boolean
        //total?: number
        //available?: number
    }[]
}

export interface FilterData<T = FilterDataData> {
    id: string | number
    name?: string
    type: FilterType
    data: T
}

export interface FilterValue {
    filter: string | RangeValue | DictValue,
    id: string | number | null,
    name: string,
    filter_name: string,
    type: string
}

export interface FilterRangeDataOptions {
    name: string,
    value: string | number,
}

export interface FilterRangePredefined {
    from: number,
    to: number,
    checked: boolean,
}

export interface FilterRangeData {
    id: string | number,
    type: FilterType,
    data: {
        options: FilterRangeDataOptions[],
        placeholder: RangeValue,
        predefined_ranges: RangeValue[],
    }
}

export interface FilterDictionaryData {
    options: {
        id: string,
        name: string,
        amount: number | string,
    }[],
    popular_dictionary_values: number[] | string[],
}

export interface FilterTextData {
    label: string,
    options: {
        text: string | number
    },
}

export interface AsideFiltersState {
    //data: null | []
    data: FilterData[] | null
    //values: null | {}
    values: Record<string, RangeValue | DictValue> | null
    isActive: Record<string, boolean>
    smartFilters: SmartFilters
    allowFiltered: boolean
    allowUpdating: boolean
    disableEmptyValues: boolean
}

export function filterOptionIsDisabled ({
    filterId, /*filterIsActive,*/ optId, optName, smartFilters
} : {
    filterId: string,
    //filterIsActive: Record<string, boolean>,
    optId?: string,
    optName?: string,
    smartFilters: SmartFilters
})
{
    let available = 0;

    /*
    if (filterId.startsWith('fltr'))
    {
        available = smartFilters?.[filterId]?.[optName];
    }
    else
    {
        available = smartFilters?.[filterId]?.[optId];
    }
    */
    available = smartFilters?.[filterId]?.[optName];

    const optIsActive = available !== undefined && available > 0;

    return (
        optIsActive !== undefined &&
        optIsActive === false
        //&& !filterIsActive?.[filterId]
    );
}
