import {useRef, useEffect, useState} from 'react'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import TDXOutlinedInput from './TDXOutlinedInput'
import TDXButton from '../Buttons/TDXButton'
import {useAppSelector} from "../../../shared/hooks/hooks";

const TDXIntegerInput = ({counter = false, max, value = 0, onChange, disabled = false, allowNull = false, maxStr = 0, ...other}:{
    counter?: boolean,
    max?: number | null,
    value: number,
    onChange: (val?: any) => void,
    disabled?: boolean,
    allowNull?: boolean,
    maxStr?: number,
    placeholder?: string | number,
}) => {
    const maxValue = max >= 0 ? Number(max): (counter ? 99 : 999999)
    const _input = useRef()
    const [maxValueStr, setMaxValueStr] = useState(`Max. ` + maxValue)
    const {settings} = useAppSelector(state => state.General.common)

    useEffect(() => {
        if (maxStr > 0 && maxValue > maxStr && settings?.quantity_choices?.[maxStr]) {
            setMaxValueStr(settings?.quantity_choices?.[maxStr].value as string)
        } else if (maxStr === -1) {
            setMaxValueStr('')
        }
    },[maxValue, settings, maxStr])

    const onChangeHandler = (e: any) => {
        if (allowNull) {
            const value_tmp = e.target.value
            onChange(Number.isNaN(Number(value_tmp)) ? value: value_tmp > maxValue ? maxValue : value_tmp)
        } else {
            const value_tmp = e.target.value ? parseInt(e.target.value, 10): 0
            onChange(Number.isNaN(Number(value_tmp)) ? value : value_tmp > maxValue ? maxValue : value_tmp)
        }
    }

    const changeValueByButtons = (type: 'increment' | 'decrement') => () => {
        const value = parseInt(_input?.current ? _input?.current?.['value']: null, 10)

        onChange(
            {
                increment: (v: number) => (v < maxValue ? v + 1 : v),
                decrement: (v: number) => (v > 0 ? v - 1 : v),
            }[type](value)
        )
    }

    return (
        <>
            {counter ? (
                <TDXOutlinedInput
                    {...other}
                    className="counter"
                    value={Number.isNaN(value) || value === null ? '': value}
                    onChange={onChangeHandler}
                    helperText={maxValueStr}
                    inputRef={_input}
                    InputProps={{
                        startAdornment: (
                            <TDXButton
                                size="small"
                                bg="gray icon"
                                onClick={changeValueByButtons('decrement')}
                                disabled={disabled}>
                                <RemoveIcon />
                            </TDXButton>
                        ),
                        endAdornment: (
                            <TDXButton
                                size="small"
                                bg="gray icon"
                                onClick={changeValueByButtons('increment')}
                                disabled={disabled}>
                                <AddIcon />
                            </TDXButton>
                        ),
                    }}
                    disabled={disabled}
                />
            ) : (
                <TDXOutlinedInput
                    {...other}
                    className="integer"
                    value={Number.isNaN(value) || value === null ? '': value}
                    onChange={onChangeHandler}
                />
            )}
        </>
    )
}

export default TDXIntegerInput
