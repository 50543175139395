import CatalogService, {CatalogRequestsEnum} from '../../api/catalog.service'
import {requestHelper} from '../../helpers/requestHelper'

export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_PRODUCT_PRICES = 'SET_PRODUCT_PRICES'

export const getProduct = (id: string, signal: any) => (dispatch: any) => {
    requestHelper(dispatch, CatalogRequestsEnum.getProduct(id), async () => {
        const product = await CatalogService.getProduct(id, signal)

        dispatch({
            type: SET_PRODUCT,
            data: {product},
        })
    })
}

export const getMarkdownProduct = (id: string, signal: any) => (dispatch: any) => {
    requestHelper(dispatch, CatalogRequestsEnum.getMarkdownProduct(id), async () => {
        const product = await CatalogService.getMarkdownProduct(id, signal)

        dispatch({
            type: SET_PRODUCT,
            data: {product},
        })
    })
}

// export const getProductPrices = (id: string) => (dispatch: any) => {
//     requestHelper(dispatch, CatalogRequestsEnum.getProductPrices(id), async () => {
//         const prices = await CatalogService.getProductPrices(id)
//
//         dispatch({
//             type: SET_PRODUCT_PRICES,
//             data: {prices},
//         })
//     })
// }
